import http from '@/utils/http'
//
// 商砼/砂浆-日报表列表
export const getDayUpleReportList = p => http.get('/material/dataUple/getDayUpleReportList', p)

// 商砼/砂浆-月报表列表
export const getMonthUpleReportList = p => http.get('/material/dataUple/getMonthUpleReportList', p)

// 商砼/砂浆-年报表列表
export const getYearUpleReportList = p => http.get('/material/dataUple/getYearUpleReportList', p)

// 数据上报字段获取
export const getDataColumn = p => http.get('/material/dataUple/getDataColumn', p)

// 删除报表数据
export const deleteUpleData = p => http.post('/material/dataUple/deleteUpleData', p)

// 新增或编辑报表数据
export const postUpleData = p => http.post('/material/dataUple/postUpleData', p)

// 上报报表数据
export const uploadUpleData = p => http.post('/material/dataUple/uploadUpleData', p)

// 设备上报列表
export const getDataUpleDevList = p => http.get('/material/upleDev/getDataUpleDevList', p)

// 新增或编辑上报设备
export const addOrUpdateDataUpleDev = p => http.post('/material/upleDev/addOrUpdateDataUpleDev', p)

// 删除上报设备
export const deleteDataUpleDev = p => http.post('/material/upleDev/deleteDataUpleDev', p)

// 提交上报设备明细
export const commitUpleDevDetail = p => http.post('/material/upleDev/commitUpleDevDetail', p)

// 新增或编辑上报设备明细
export const addOrUpdateDataUpleDevDetail = p => http.post('/material/upleDev/addOrUpdateDataUpleDevDetail', p)

// 设备上报操作日志
export const getDataUpleDevLog = p => http.get('/material/upleDev/getDataUpleDevLog', p)

// 商砼/砂浆-导出上报的日报表
export const exportDayUpleReport = p => http.postFormExcel('/material/dataUple/exportDayUpleReport', p)

// 商砼/砂浆-导出上报的月报表
export const exportMonthUpleReport = p => http.postFormExcel('/material/dataUple/exportMonthUpleReport', p)

// 商砼/砂浆-导出上报的年报表
export const exportYearUpleReport = p => http.postFormExcel('/material/dataUple/exportYearUpleReport', p)

// 商砼/砂浆-获取未提交日月年报表列表
export const getNoSubmitUpleReportList = p => http.get('/material/dataUple/getNoSubmitUpleReportList', p)
